.Home {
    max-width: 1400px;
    width: 100%;
    margin: auto;
    position: relative;

    .ButtonWrap {
        display: flex;
        justify-content: space-between;
        max-width: 1400px;
        width: 100%;
        padding: 0 0 05px 0;
        margin-bottom: 5px;

        @media screen and (max-width: 768px) {
            justify-content: center;
        }

        .Accordion {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }
    }

    .ChartContainer {
        padding-top: 10px;
        margin: auto;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow: auto;
        gap: 10px;
        padding-bottom: 20px;

        @media screen and (max-width: 768px) {
            justify-content: center;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            overflow: auto;
            gap: 10px;
        }

        .Chart {
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
    }

    .GraphLoader {
        width: 100%;
        height: 100%;
        z-index: 105;
        backdrop-filter: blur(5px);
        display: flex;
        background-color: rgba(0, 0, 0, 0.1);
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.SubmitBtn {
    width: 200px;
    height: 70px;
    background: #0caf7a;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.04em;
    padding: 12.5px 16px;
    margin: 20px 0 0 20px;
}