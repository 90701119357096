.Container {
    display: flex;
    height: 100%;
    width: 100%;
    background: #ffffff;
    color: #000000;
    position: fixed;

    .MainContent {
        overflow-y: auto;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 0 16px;

        @media screen and (max-width: 768px) {
            padding: 0 10px 0 10px;
        }

        .Content {

            @media screen and (max-width: 768px) {
                padding: 0 0 65px 0;
            }

            flex-grow: 1;
            // overflow-y: auto;
            max-width: 1140px;
            margin: auto;
            width: 100%;
        }
    }
}