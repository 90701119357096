.Wraper {
  display: none;

  @media screen and (max-width:768px) {


    display: flex;
    justify-content: flex-end;
    width: 100%;

    .MainContainer {
      width: 166px;
      height: 30px;
      margin: 25px 36px 33px 0;
      text-align: right;

      .PagesMenu {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .Page {
          width: 26px;
          height: 26px;
          justify-content: center;
          display: flex;
          border-radius: 2px;
          align-items: center;

          p {
            margin: 0;
          }
        }

        .PageIcon {
          width: 14px;
        }
      }
    }
  }
}