.MainContainer {
 height: 100%;
 padding: 0 32px 0 32px;
 display: flex;
 align-items: center;
 justify-content: center;
 position: relative;

 .Container {
  display: flex;
  max-width: 1400px;
  width: 100%;
  justify-content: space-between;
  margin: auto;
  padding: 0 0 25px 0;

  @media screen and (max-width: 768px) {
   display: block;
   max-width: 100%;
  }

  .Accordion {
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;

   //    padding: 0px 0 0 0;
   @media screen and (max-width: 768px) {
    justify-content: center;
   }
  }
 }

 .FormWrap {
  max-width: 500px;
  height: fit-content;
  width: 100%;
  background: #f5f5f5;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin: auto;
  // margin-top: 60px;
  color: #000000;

  .DropDown {
   height: 200px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   margin: 30px 0;
  }

  .Btn {
   text-align: center;
   margin: auto;

   button {
    height: 48px;
    background: #0caf7a;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.04em;
    padding: 12.5px 16px;
    margin: 50px 0 50px 0;
   }
  }
 }

 .Note {
  font-size: 12px;
  padding-bottom: 10px;
  text-align: center;
 }

 p {
  text-align: center;
  color: red;
 }

 .Loader {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  filter: blur(60%);
 }
}
