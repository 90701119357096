.Row {
 box-sizing: border-box;
 height: fit-content;
 display: flex;
 align-items: center;

 font-family: "Outfit";
 font-style: normal;
 font-weight: 500;
 font-size: 16px;
 line-height: 20px;
 text-align: center;
 letter-spacing: 0.04em;

 color: #000000;

 opacity: 0.5;
 border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);

 &:last-child {
  border-bottom: unset;
 }
}
