.Container {
  display: flex;
  justify-content: center;
  @media screen and (max-width: 768px) {
    // padding: 10px 12px;

    margin: auto;
  }
  .Footer {
    max-width: 1400px;
    width: 100%;
    height: 42px;
    background: rgba(247, 129, 119, 0.2);
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    letter-spacing: 0.04em;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      width: 328px;
      height: 35px;
    }
    .Total {
      color: #000000;
      padding: 0 36px 0 16px;
    }
    .Active {
      color: #0caf7a;
    }
  }
}
