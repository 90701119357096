.MainContainer {
    max-width: 328px;
    width: 100%;
    background: #f5f5f5;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: auto;
    margin-bottom: 25px;
    padding-bottom: 15px;

    .Container {
        margin: 16px 16px 0 16px;
        display: flex;
        flex-wrap: wrap;

        .TableWrap {
            display: flex;
            max-width: 330px;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;

            .ItemList {
                flex-basis: 100%;
                letter-spacing: 0.04em;
                border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
                margin-top: 5px;

                &.Single {
                    flex-basis: 100%;
                }

                .Title {
                    font-weight: 500;
                    font-size: 12px;
                    margin: 0;
                    line-height: 15px;
                }

                .Desc {
                    font-weight: 300;
                    font-size: 14px;
                    opacity: 0.5;
                    margin: 2px 0 12px 0;
                }
            }
        }

        .BtnContainer {
            display: flex;
            justify-content: space-between;
            margin: 12px 0 0 0;
            width: 100%;

            .EditButton {
                width: 140px;
                height: 40px;
                color: #0caf7a;
                background: #f5f5f5;
                border-radius: 4px;
                letter-spacing: 0.04em;
                border: 1.5px solid #0caf7a;
            }

            .DeleteButton {
                color: #ffffff;
                width: 140px;
                height: 40px;
                background: #0caf7a;
                border: 1.5px solid #0caf7a;
                border-radius: 4px;
                letter-spacing: 0.04em;
            }
        }
    }
}