.MainWrap {
    width: 100%;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 8px;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .HeaderWrap {
        display: flex;
        justify-content: space-between;
        padding: 0;

        .ValuesContainer {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .MinMaxContainer {
                display: flex;
                margin: 0;

                .MinMax {
                    margin: 0;
                    font-family: "Outfit";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: right;
                    letter-spacing: 0.04em;

                    color: #000000;

                    opacity: 0.7;

                    span {
                        font-size: 16px;
                        font-weight: 900;
                        opacity: 0.8;
                    }
                }
            }

            .Value {
                font-weight: 600;
                font-size: 21px;
                text-align: right;
                letter-spacing: 0.04em;
                color: #f85b4d;
                margin: 0;
            }
        }

        .Header {
            font-weight: 550;
            font-size: 18px;
            letter-spacing: 0.04em;
            margin: 0;
            color: #000000;
        }
    }

    .Chart {
        width: 100%;
    }
}