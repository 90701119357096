.MessageWrap {
 background: #49ff2d;
 width: fit-content;
 margin: 20px auto;
 height: fit-content;
 padding: 10px;
 border-radius: 5px;
 color: #f5f5f5;
 letter-spacing: 0.05em;
 font-size: 20px;
 display: flex;
 align-items: center;
 justify-content: center;
 margin-top: 40px;
 @media screen and (max-width: 768px) {
  width: 290px;
  height: 30px;
  font-size: 14px;
 }
 //    display: none;
}
