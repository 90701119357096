// .calendarBtn {
//  width: 200px;
//  background: #0caf7a;
//  border-radius: 4px;
//  font-weight: 600;
//  font-size: 18px;
//  letter-spacing: 0.04em;
//  padding: 12.5px 16px;
//  margin: 20px 0 0 0;
//  //  background-color: ;
// }

.CalendarContainer {
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 width: 100vw;
 height: 100vh;
 z-index: 100;
 backdrop-filter: blur(12.5px);
 display: flex;
 justify-content: center;
 align-items: center;
}

// .calendar {
//  margin: 20px 0 0 0;
// }

.CalendarWrapper {
 display: flex;
 justify-content: space-around;
 align-items: center;
 padding: 12px 16px;
 width: 355px;
 height: 48px;
 background: #f5f5f5;
 border: 0.5px solid rgba(0, 0, 0, 0.25);
 border-radius: 4px;

 .Heading {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #000000;

  opacity: 0.5;
 }
 .Date {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #000000;
 }
}
