.Row {
  margin: auto;
  width: 100%;
  max-width: min(100%, 1400px);
  min-height: 48px;
  color: #000000;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.25);
}
.Col {
  text-align: center;
  letter-spacing: 0.04em;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding: 14px 0 0 0;
}
.Icons {
  display: flex;
  justify-content: space-evenly;
  max-width: 100px;
  margin: auto;
}
