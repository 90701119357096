.OptionsAccordion {
 width: 300px;
 // width: 100%;
 height: 38px;
 background: #f5f5f5;
 border: 0.5px solid rgba(0, 0, 0, 0.25);
 border-radius: 4px;
 display: flex;
 justify-content: space-between;
 align-items: center;
 color: #000000;
 margin-right: 10px;
 padding: 0 10px 0 10px;
 margin: 0 30px 10px 0;
 //  overflow: hidden;

 @media screen and (max-width: 768px) {
  margin: 0 0 20px 0;
 }

 .LeftText {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.04em;
  opacity: 0.5;

  // max-width: 110px;
  // width: 100%;
  @media screen and (max-width: 768px) {
   font-size: 12px;
  }
 }

 .Devider {
  height: 23px;
  border: 0.5px solid rgba(0, 0, 0, 0.25);
 }

 .DropDown {
  letter-spacing: 0.04em;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 768px) {
   font-size: 12px;
  }
 }
}
