.Container {
  display: flex;
  justify-content: center;
  button {
    height: 48px;
    background: #0caf7a;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.04em;
    padding: 12.5px 16px;
    @media screen and (max-width: 768px) {
      position: fixed;
      bottom: 100px;
      right: 40px;
      padding: 0;
      width: 48px;
      background: #f85b4d;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
