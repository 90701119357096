.Header {
    box-sizing: border-box;
    height: 48px;

    background: #F5F5F5;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    align-items: center;

    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.04em;

    color: #000000;

}