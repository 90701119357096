.DeleteWrapper {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12.5px);
  z-index: 100;
  height: 100%;
  width: 100%;
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  .Wrapper {
    max-width: 475px;
    width: 100%;
    height: 206px;
    background: #f5f5f5;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    text-align: center;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media screen and (max-width: 768px) {
      max-width: 328px;
      height: 138px;
    }
    .Content {
      .Title {
        font-weight: 600;
        font-size: clamp(14px, 1.667vw, 24px);
        letter-spacing: 0.04em;
        margin: 25px 0 15px 0;
        @media screen and (max-width: 768px) {
          margin: 16px 0 8px 0;
        }
      }
      .Desc {
        font-weight: 500;
        font-size: clamp(12px, 1.25vw, 18px);
        letter-spacing: 0.04em;
        color: rgba(0, 0, 0, 0.5);
        margin: 0;
      }
    }

    .Buttons {
      max-width: 425px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: auto;
      @media screen and (max-width: 768px) {
        max-width: 296px;
      }
      .Cancel {
        max-width: 200px;
        width: 100%;
        height: 48px;
        border: 2px solid #0caf7a;
        border-radius: 4px;
        font-weight: 600;
        font-size: clamp(16px, 1.25vw, 18px);
        letter-spacing: 0.04em;
        color: #0caf7a;
        background: none;
        @media screen and (max-width: 768px) {
          max-width: 140px;
          height: 40px;
        }
      }
      .Delete {
        max-width: 200px;
        width: 100%;
        height: 48px;
        background: #0caf7a;
        border-radius: 4px;
        font-weight: 600;
        font-size: clamp(16px, 1.25vw, 18px);
        letter-spacing: 0.04em;
        @media screen and (max-width: 768px) {
          max-width: 140px;
          height: 40px;
        }
      }
    }
  }
}
