.Forgot {
 height: 100vh;
 background: #ffffff;
 display: flex;
 color: #000000;
 .Container {
  margin: auto;
  .FormWrap {
   width: 620px;
   height: 620px;
   background: #f5f5f5;
   margin: auto;
   border: 0.5px solid rgba(0, 0, 0, 0.25);
   border-radius: 4px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   @media screen and (max-width: 768px) {
    max-width: 320px;
    height: 450px;
   }
   .HeaderWrap {
    text-align: center;
    margin-top: 5px;
    .LogoWrap {
     padding: 0 0 10px 0;
     display: flex;
     justify-content: space-around;
     align-items: center;
     .CdacLogo {
    
      height: 54px;
      @media screen and (max-width: 768px) {
      height: 40px;
      }
     }
     .AgriLogo {
      width: 84px;
      @media screen and (max-width: 768px) {
       width: 60px;
      }
     }
     .DprLogo {
      width: 54px;
      height: 54px;
      @media screen and (max-width: 768px) {
       width: 36px;
       height: 35px;
      }
     }
    }

    .TextContainer {
     width: 609px;
     margin: auto;
     @media screen and (max-width: 768px) {
      width: 100%;
     }
     .Title {
      font-weight: 600;
      font-size: 28px;
      text-align: center;
      letter-spacing: 0.04em;
      color: #000000;

      @media screen and (max-width: 768px) {
       font-size: 21px;
      }
     }
     .Tag {
      letter-spacing: 0.04em;
      font-family: "Outfit";
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: rgba(0, 0, 0, 0.62);

      @media screen and (max-width: 768px) {
       font-size: 12px;
       margin: 0 10px;
      }
     }
    }
   }

   form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 145px;
    justify-content: space-between;
    margin: 40px 0;
    @media screen and (max-width: 768px) {
     margin: 20px 0;
     height: 105px;
    }
    input {
     width: 400px;
     height: 50px;
     background: #f5f5f5;
     border: 0.5px solid rgba(0, 0, 0, 0.25);
     border-radius: 4px;
     font-weight: 500;
     font-size: 16px;
     line-height: 23px;
     letter-spacing: 0.04em;
     padding-left: 25px;
     &:focus {
      outline: none;
     }

     @media screen and (max-width: 768px) {
      width: 250px;
      font-size: 14px;
      font-weight: 300;
      height: 38px;
     }
    }
   }
  }
  .Button {
   text-align: center;
   Button {
    font-weight: 600;
    font-size: 21px;
    background: #0caf7a;
    letter-spacing: 0.04em;
    max-width: 400px;
    width: 100%;
    height: 58px;
    border-radius: 4px;

    @media screen and (max-width: 768px) {
     width: 250px;
     height: 40px;
     font-weight: 600;
     font-size: 16px;
     line-height: 20px;
    }
   }
  }
 }
}
