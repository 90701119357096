.NewUserWrap {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12.5px);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .NewUser {
    max-width: 380px;
    text-align: center;
    width: 100%;
    background: #f5f5f5;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    @media screen and (max-width: 768px) {
      max-width: 290px;
    }

    .Close {
      text-align: right;
      margin: 16px 16px 5px 0;

      @media screen and (max-width: 768px) {
        margin: 8px 8px 0 0;
      }
    }

    .UserWrap {
      text-align: center;
      max-width: 320px;
      width: 100%;
      margin: auto;

      .Title {
        font-weight: 600;
        font-size: clamp(16px, 1.667vw, 24px);
        letter-spacing: 0.04em;
        margin: 0;
        text-align: center;
        margin: 0 0 30px 0;

        @media screen and (max-width: 768px) {
          margin: 0 0 20px 0;
        }
      }
    }

    .InputWrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: clamp(14px, 1.25vw, 18px);

      @media screen and (max-width: 768px) {
        max-width: 250px;
        margin: auto;
      }

      input::placeholder {

        // width: 100%;
        // background: none;
        // opacity: 0.5;
        // letter-spacing: 0.04em;
        // padding: 8px;

        // @media screen and (max-width: 768px) {
        //   padding-left: 12px;
        // }
      }

      input {

        border: 0.5px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        width: 100%;

        background: #fff;
        padding-left: 16px;
        opacity: 0.5;
        letter-spacing: 0.04em;
        color: #000;
        margin-bottom: 10px;

        padding: 8px;

        @media screen and (max-width: 768px) {
          padding-left: 12px;
        }
      }

      .Error {
        border: 2px solid red;
      }
    }

    button {
      background: #0caf7a;
      border-radius: 4px;
      width: 100%;
      height: 48px;
      font-weight: 600;
      font-size: clamp(16px, 1.25vw, 18px);
      letter-spacing: 0.04em;
      margin: 30px 0;

      @media screen and (max-width: 768px) {
        max-width: 250px;
        height: 40px;
        margin: 20px 0 25px 0;
      }
    }
  }
}