.Container {
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 position: fixed;
 top: 100px;
 left: 117px;
 right: 17px;
 bottom: 0px;
 z-index: 100;
 background-color: grey;

 .Heading {
  color: white;
  font-size: 24px;
 }
 .LogoutBtn {
  width: fit-content;
  height: fit-content;
  padding: 20px 30px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.712);
  background-color: burlywood;
  border: 0;
  border-radius: 10px;
  &:focus {
   outline: none;
  }
 }
}
