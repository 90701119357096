.react-calendar {
 width: 400px;
 max-width: 100%;
 background-color: #fff;
 color: #222;
 height: 330px;
 border-radius: 15px;
 box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
 font-family: Arial, Helvetica, sans-serif;
 line-height: 1.125em;
}
.react-calendar__month-view__weekdays {
 display: flex;
 align-items: center;
 justify-content: space-around;
 font-size: 18px;
 font-weight: 900;
 padding: 20px 0px 20px 20px;
 font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
  "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.react-calendar__tile--active {
 background-color: rgba(246, 41, 232, 0.854) !important;
 /* width: 0px !important; */
 /* flex: 0 20px 50px !important; */
 /* height: 40px !important; */
 border-radius: 0px !important;
 opacity: 0.7;
 color: #fff !important;
 /* margin: 5px 5.7px 0 0px !important; */
}
.react-calendar__tile--rangeStart {
 opacity: 1;
}
.react-calendar__tile--rangeEnd {
 opacity: 1;
}
.react-calendar__month-view__days__day {
 font-size: 18px;
 font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
  "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
 background: transparent;
 color: black;
 padding: 6px;
}
.react-calendar__month-view__days {
 padding: 0 0 0 10px;
}
.react-calendar__month-view__days button[disabled] {
 background-color: grey;
 opacity: 0.7;
 color: rgba(34, 34, 34, 0.543);
}
.react-calendar__navigation {
 border-bottom: 2px solid rgba(0, 0, 0, 0.292);
 height: 74px;
 text-align: center;
}
.react-calendar__navigation button {
 margin: 25px 0px 10px 15px;
}
.react-calendar__navigation__label__labelText {
 font-size: 22px;
 background-color: transparent;
 color: black;
 font-weight: 700;
 margin: 0px 0 0px 0px;
}
.react-calendar__navigation__label {
 background-color: transparent;
}
.react-calendar__navigation__prev-button {
 background-color: rgba(246, 41, 232, 0.854) !important;
 width: 35px;
 height: 35px;
 border-radius: 5px;
 font-size: 27px !important;
 padding: 0px 0 3px 0;
 color: #fff;
}
.react-calendar__navigation__next-button {
 background-color: rgba(246, 41, 232, 0.854) !important;
 width: 35px;
 height: 35px;
 border-radius: 5px;
 font-size: 27px !important;
 padding: 0px 0 3px 0;
 color: #fff;
}
.react-calendar__navigation__arrow {
}
.react-calendar__navigation__prev2-button {
 display: none;
}
.react-calendar__navigation__next2-button {
 display: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
 background-color: #f8fafa;
}
.react-calendar__navigation button[disabled] {
 background-color: #f0f0f0;
}
abbr[title] {
 text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
 background: #f8f8fa;
 color: #6f48eb;
 border-radius: 6px;
}
.react-calendar__tile--now {
 background: #6f48eb33;
 border-radius: 6px;
 font-weight: bold;
 color: #6f48eb;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
 background: #6f48eb33;
 border-radius: 6px;
 font-weight: bold;
 color: #6f48eb;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
 background: #f8f8fa;
}
.react-calendar__tile--active {
 background: #6f48eb;
 border-radius: 6px;
 font-weight: bold;
 color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
 background: #6f48eb;
 color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
 background-color: #f8f8fa;
}
.react-calendar__tile--range {
 background: #f8f8fa;
 color: #6f48eb;
 border-radius: 0;
}
.react-calendar__tile--rangeStart {
 border-top-right-radius: 0;
 border-bottom-right-radius: 0;
 border-top-left-radius: 6px;
 border-bottom-left-radius: 6px;
 background: #6f48eb;
 color: white;
}
.react-calendar__tile--rangeEnd {
 border-top-left-radius: 0;
 border-bottom-left-radius: 0;
 border-top-right-radius: 6px;
 border-bottom-right-radius: 6px;
 background: #6f48eb;
 color: white;
}
