.Signup {
    height: 100vh;
    background: #ffffff;
    display: flex;
    color: #000000;

    @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .Container {
        margin: auto;

        .FormWrap {
            width: 622px;
            height: 622px;
            background: #f5f5f5;
            border: 0.5px solid rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            @media screen and (max-width: 768px) {
                max-width: 300px;
                height: 500px;
            }

            .HeaderWrap {
                text-align: center;

                .LogoWrap {

                    display: flex;
                    justify-content: space-around;
                    align-items: center;


                    @media screen and (max-width: 768px) {}

                    .CdacLogo {
                        height: 54px;

                        @media screen and (max-width: 768px) {
                            height: 40px;
                        }
                    }

                    .AgriLogo {
                        height: 84px;

                        @media screen and (max-width: 768px) {
                            height: 60px;
                        }
                    }

                    .DprLogo {
                        width: 54px;
                        height: 54px;

                        @media screen and (max-width: 768px) {
                            width: 36px;
                            height: 35px;
                        }
                    }
                }

                .Title {
                    font-weight: 600;
                    font-size: 28px;
                    text-align: center;
                    letter-spacing: 0.04em;
                    color: #000000;
                    margin: 10px 0 0 0;

                    @media screen and (max-width: 768px) {
                        font-weight: 500;
                        font-size: 21px;

                    }
                }
            }

            .FormContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;


                @media screen and (max-width: 768px) {}

                .Form {
                    width: 350px;
                    height: 40px;
                    background-color: transparent;
                    border: 0.5px solid rgba(0, 0, 0, 0.25);
                    border-radius: 4px;
                    font-weight: 500;
                    font-size: 16px;
                    letter-spacing: 0.04em;
                    padding-left: 25px;

                    &:focus {
                        outline: none;
                    }

                    @media screen and (max-width: 768px) {
                        width: 250px;
                        font-size: 14px;
                        font-weight: 300;
                        height: 35px;
                    }
                }

                .FormField {
                    display: flex;
                    flex-direction: column;
                    height: 345px;
                    justify-content: space-between;

                    @media screen and (max-width: 768px) {
                        height: 300px;
                    }
                }
            }
        }
    }
}

.Button {
    @media screen and (max-width: 768px) {
        width: fit-content;
        height: fit-content;
        width: 50px;
    }

    Button {
        font-weight: 600;
        font-size: 21px;
        background: #0caf7a;
        color: #f5f5f5;
        letter-spacing: 0.04em;
        max-width: 350px;
        width: 100%;
        height: 44px;
        border-radius: 4px;

        @media screen and (max-width: 768px) {
            width: 100px;
            height: 40px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }
    }
}