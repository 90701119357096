.Header {
 color: #000000;
 padding: 20px 0;
 background: #ffffff;
 width: 100%;
 max-width: 1140px;
 margin: auto;
 border-bottom: 0.5px solid rgba(0, 0, 0, 0.4);
 margin-bottom: 20px;

 .Wrapper {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
   flex-direction: row-reverse;
   align-items: center;
   justify-content: space-around;
  }

  .LogOutWrap {
   width: 26px;
   height: 26px;
  }

  .MainHeader {
   display: flex;
   .Farms {
    margin: 0 0 0 20px;
   }
   .PageTitle {
    letter-spacing: 0.04em;

    @media screen and (max-width: 768px) {
     text-align: center;
    }

    .Title {
     font-weight: 600;
     font-size: 32px;
     margin: 0 0 4px 0;

     @media screen and (max-width: 768px) {
      width: 100%;
      font-size: 21px;
      line-height: 26px;
     }
    }

    .Desc {
     font-weight: 400;
     font-size: 14px;
     opacity: 0.5;
     margin: 0;

     @media screen and (max-width: 768px) {
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
     }
    }
   }
  }
 }

 .UserWrap {
  display: flex;
  align-items: center;

  .UserDetails {
   font-weight: 400;
   letter-spacing: 0.04em;
   margin: 0 12px 0 0;

   .UserName {
    font-size: 18px;
    margin: 0 0 4px 0;
   }

   .Role {
    font-size: 14px;
    opacity: 0.5;
    margin: 0;
    text-align: end;
   }
  }

  .UserIcon {
   @media screen and (max-width: 768px) {
    width: 26px;
   }
  }
 }
}
