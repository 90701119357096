.Devices {
  .ButtonWrap {
    display: flex;
    justify-content: space-between;
    max-width: 1400px;
    margin: auto;
    padding: 0 0 25px 0;
    @media screen and (max-width: 768px) {
      display: block;
      max-width: 100%;
    }
    .Accordion {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    .AddBtn {
      position: fixed;
      right: 40px;
      bottom: 70px;
      // bottom: 0;
      // display: none;
      // @media screen and (max-width: 768px) {
      //   display: block;
      //   width: 32px;
      //   display: flex;
      //   margin-left: 40rem;
      //   justify-content: end;
      // }
    }
  }
  
  .TableWrap {
    padding: 25px;
    width: 100%;
    background: #ffffff;
    @media screen and (max-width: 768px) {
      display: none;
    }
 
  }
}
