.Header {
  background: #f5f5f5;
  height: 100%;
  width: 117px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 20px 0;
  border-right: 0.5px solid rgba(0, 0, 0, 0.25);
  .OptionsWrap {
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .Options {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      // color: #000000
      margin: 0;
      cursor: pointer;
    }
  }
  .LogOutWrap {
    @media screen and (max-width: 768px) {
      position: fixed;
      right: 40px;
      top: 28px;
    }

    .LogOut {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #000000;
      margin: 0;
      cursor: pointer;
    }
  }
}

// MOBILE VIEW //

.MobileMenu {
  background: #f5f5f5;
  height: 60px;
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  // overflow-y: auto;
  .Wrapper {
    max-width: 300px;
    width: 100%;
    .OptionsWrap {
      display: flex;

      margin: auto;
      justify-content: space-between;
    }
  }
}
